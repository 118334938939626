.successElement {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  width: 200px;
  height: 100px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  text-align: center;
  border-radius: 12px;
  text-decoration: none;
}

.navButton{
    text-decoration: none;
    color: black;
}
.navButton:hover{
    text-decoration: none;
    color: black;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 110px;
  padding-bottom: 80px;
  /* for edge cases: */
  width: 100%;
  max-width: 100%;
  text-decoration: none;
}


@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

