.container > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 2; */
}

.content{
  padding-bottom: 3rem;
}

.text {
  justify-content: start !important;
  font-family: "Kanit", sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

/* .number {
  font-size: 450px;
  color:black;
}

.number span {
  display: inline-block;
  position: relative;
  transform: translate3d(-35%, 0, 0);
} */

.header {
  margin-left: 350px;
  font-size: 4.375rem;
  color: white;
}

.stripe {
  height: 2px;
  width: auto;
}

.slopeBegin {
  background-color: #20232f;
  clip-path: polygon(20vw 0, 70% 0, calc(70% - 20vw) 100%, 0 100%);
}

.slopeEnd {
  clip-path: polygon(
    70% 0,
    100% 0,
    calc(100% - 20vw) 100%,
    calc(70% - 20vw) 100%
  );
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 140%;
  height: 100%;
  cursor: pointer;
}

/* .pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
} */

.bottomLeft {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.topLeft {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.topLight {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottomRight {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: rgb(66, 65, 65); */
  color: black;
  font-size: 5vh;
}

.container a {
  /* background-color: rgb(184, 137, 228); */
  color: black;
  text-decoration: none;
}
