.wrapperList {
  display: flex;
  justify-content: center;
}
.listitems {
  display: flex;
  justify-content: left;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;
}
th,
td {
  padding: 8px;
  
}
tr:nth-child(even) {
  background-color: #afa596;
}
