a {
  /* background-color: rgb(184, 137, 228); */
  color: black;
  text-decoration: none;
 
}

i {
  color: black;
  text-decoration: none;
  
}

p {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.ContactTitle {
  font-size: 1rem;
}
.Center a {
  /* background-color: rgb(184, 137, 228); */
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: medium;
  text-decoration: none;
}

.wrapper:hover a {
  text-decoration: none;
}

.header h1 {
  color: black;
  font-size: x-large ;
}
.header {
  text-align: center;
  
}

.ContactText {
  padding: 1rem;
  text-align: center;
}
.ContactText h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: x-large ;
}
.googleMap {
  padding: 1rem;
  text-align: center;
}
/* .googleMapShows{
    padding-bottom: 20rem;
} */
.contactForm {
  padding: 1rem;
  text-align: center;
}

.contactImpressum {
  padding: 1rem;
  text-align: center;
}

.contactImpressum h2 {
  text-align: center;
}

.contactInstagram{
  padding: 1rem;
}

@media screen and (max-width: 960px) {
  a {
    /* background-color: rgb(184, 137, 228); */
    color: black;
    text-decoration: none;
  }

  i {
    color: black;
    text-decoration: none;
  }

  p {
    color: black;
    text-decoration: none;
  }
  .ContactTitle {
    font-size: 0.2rem;
  }
  .Center a {
    /* background-color: rgb(184, 137, 228); */
    color: black;
    text-decoration: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 1.5rem;
    font-size: small;
  }

  .header h1 {
    color: black;
  }
  .header {
    text-align: center;
  }

  .ContactText {
    padding: 0.2rem;
    text-align: center;
  }
  .ContactText h2 {
    text-align: center;
    text-transform: uppercase;
  }
  .googleMap {
    padding: 0.2rem;
    text-align: center;
  }
  .contactInstagram{
    padding: 0.2rem;
  }
  /* .googleMapShows{
        padding-bottom: 20rem;
    } */
  .contactForm {
    padding: 0.2rem;
    text-align: center;
  }

  .contactImpressum {
    padding: 0.2rem;
    text-align: center;
  }

  .contactImpressum h2 {
    text-align: center;
  }
}

