.wrapper{
    background-color: #a69c8f;
  }
  
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 110px;
    padding-bottom: 80px;
    /* for edge cases: */
    width: 100%;
    max-width: 100%;
  }
  .content{
    padding-bottom: 3rem;
  }
  @media (min-width: 768px) {
    .container{
      width: 750px;
    }
  }
  
  @media (min-width: 992px) {
    .container{
      width: 970px;
    }
  }
  
  @media (min-width: 1200px) {
    .container{
      width: 1170px;
    }
  }