.textInBox {
  color: white;
}

.responsive {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6)
}

.containerProducts {
  padding-top: 80px;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 2vh;
  
}

.contentProducts {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.26);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 767px) {
  .contentProducts {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.26);
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
  }

  /* .responsive {
    width: 100%;
    max-width: 800px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6)
  } */
}
