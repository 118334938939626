.NavbarItems{
    /* background: transparent; */
    /* background: #a69c8fe5; */
    background-image: linear-gradient(to right,#30200be5, #a69c8fe5);
    backdrop-filter: blur(7px);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    position: fixed;
    top: 0;
    z-index: 3000;
    width: 100%;

}

/* .Nav__black{
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    position: fixed;
    top: 0;
    z-index: 3000;
    width: 100%;
    transition: all 2s ease-in-out;
} */

.navbar-logo{
    color: black;
    justify-self: start;
    margin-left: 0px;
    cursor: pointer;
    max-width: 200px;

}
.fa-cut{
    margin-left: 0.5rem;
    font-size: 2.5rem;
}
.nav-menu{
    /* background: #a69c8fe5; */
    display: grid;
    grid-template-columns: repeat(12, auto);
    grid-gap: 0.5rem;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    text-decoration: none;
}
.navButton{
    color:black;
    text-decoration: none;
    padding: 0.5rem 1rem;
}
.navButton:hover{
    border-bottom: 1px solid black;
    cursor: pointer;
    text-decoration: none;
    color: black;
    /* color: brown; */
}
 .navButton:focus{
    border-bottom: 1px solid black;
    cursor: default;
    text-decoration: none;
    color: black;
} 
.fa-bars{
    color:black;
}
.menu-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .NavbarItems{
        position: fixed;
        z-index: 3000;

    }
    .nav-menu{
        /* background: #a69c8fe5; */
        background-image: linear-gradient(to right,#30200be5, #a69c8fe5);
        /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 0;
        transition: all 1s ease;
        font-size: x-large;
        padding: 0;
        
    }
    .nav-menu.active{
        left: 0;
        opacity: 1;
        backdrop-filter: blur(5px);
        /* transition: all 1s ease; */
        z-index: 1;
    }
    .navButton{
        text-align: center;
        padding: 0.2rem;
        width: 100%;
        display: table;
        transition: all 3s ease-in-out;
    }
    .navButton:hover{
        border: none;
        border-radius: 0;
        transition: all 0.5s ease-in-out;
        color: black;
        cursor: pointer;
        
    }
    .navbar-logo{
        position: absolute;
        top: -74px;
        left: -46px;
        transform: translate(25%, 50%);
        font-size: 1.8rem;
    }
    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
    }
    .fa-times{
        color: black;
        font-size: 1.5rem;
    }

    /* .navButton{
        background: transparent;
        font-size: 5rem;
        color: white;
        font-size: 1.5rem;
    } */
}