.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 110px;
  padding-bottom: 80px;
  /* for edge cases: */
  width: 100%;
  max-width: 100%;
}

.aboutMeTxt{
  font-size: larger;
}

.content{
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .container{
    width: 750px;
  }
  

}

@media (min-width: 992px) {
  .container{
    width: 970px;
  }

}

@media (min-width: 1200px) {
  .container{
    width: 1170px;
  }
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.ivanaMain{
  /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6); */
  float: left;
  margin-right: 20px;
  margin-bottom: 1px;
  width: 100%;
  max-width: 350px;
  height: auto;
}

.row{
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6)
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}




