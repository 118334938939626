.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 110px;
  padding-bottom: 80px;
  /* for edge cases: */
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.onePic{
    padding: 10px;
    height: 300px;
    margin: 1rem;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6)
}

.over{
    position: relative;
    opacity: 0;
    transition: .5s ease;
}

.picCointer:hover .over{
    opacity: 1;
}

/* text */
.picGallaryHeader{
    font-size: 1.563rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@import "~react-image-gallery/styles/css/image-gallery.css";

.gridList {
  width: 1000;
  height: 1000;
}

.gridList:hover{
  cursor: pointer;
}
.holderRoot{
  display: flex;
  flex-wrap: wrap;
  justify-content: 'space-around';
  overflow: 'hidden';
} 

.icon{
  color: 'rgba(255, 255, 255, 0.54)';
}

.galeryRoot {
  flex-grow: 1;
}

.paper {
  padding: spacing(1);
  text-align: 'center';
 
}

@media only screen and (max-width: 359px) and (min-width: 200px)  {

  .gridList {
    width: 280px;
    height: 1000;
  }
}

@media only screen and (max-width: 767px) and (min-width: 360px){

  .gridList {
    width: 360px;
    height: 1000;
  }

}


.galleryImg {
  width: 300px;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  padding: 1rem;
}

.galleryImg:hover{
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}