.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 110px;
  padding-bottom: 80px;
  /* for edge cases: */
  width: 100%;
  max-width: 100%;
}
.bold{
  font-weight: bold;
}

.legalWrapper {
  margin-top: 2rem;
}

.legalHeader{
    font-size: x-large;
}


@media (min-width: 768px) {
  .container {
    width: 750px;
    font-size: x-small;
  }

  .legalHeader{
    font-size: small;
}
}

@media (min-width: 992px) {
  .container {
    width: 970px;
    font-size: medium;
  }
  .legalHeader{
    font-size: larger;
}
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
    font-size: medium;
  }
  .legalHeader{
    font-size: xx-large;
}
}
